import { frontendURL } from '../../../../helper/URLHelper';
import store from '../../../../store/index';
const SettingsContent = () => import('../Wrapper.vue');
const Index = () => import('./Index.vue');

export default {
  routes: [
    {
      path: frontendURL('accounts/:accountId/campaigns'),
      component: SettingsContent,
      props: {
        headerTitle: 'CAMPAIGN.ONGOING.HEADER',
        icon: 'arrow-swap',
      },
      // Router guard to check subscription
      beforeEnter: async (to, from, next) => {
        if (store.getters['stripe/getSubscriptionStatus'] === null) {
          const id = to.params.accountId;
          await store.dispatch('stripe/fetchSubscription', { id });
        }
        if (store.getters['stripe/getSubscriptionStatus'] !== 'active') {
          return next({ name: 'renew_subscription' });
        }

        next();
      },
      children: [
        {
          path: '',
          redirect: 'ongoing',
        },
        {
          path: 'ongoing',
          name: 'ongoing_campaigns',
          meta: {
            permissions: ['administrator'],
          },
          component: Index,
        },
      ],
    },
    {
      path: frontendURL('accounts/:accountId/campaigns'),
      component: SettingsContent,
      props: {
        headerTitle: 'CAMPAIGN.ONE_OFF.HEADER',
        icon: 'sound-source',
      },
      children: [
        {
          path: 'one_off',
          name: 'one_off',
          meta: {
            permissions: ['administrator'],
          },
          component: Index,
        },
      ],
    },
  ],
};
