<script>
export default {
    name: 'Knowledge',
    mounted() {
      const script = document.createElement('script');
      script.src = "https://js.stripe.com/v3/pricing-table.js";
      script.async = true;
      document.body.appendChild(script);
    },
    methods:{
      bypass(){
        this.$router.push({name: 'login'});
      }
    }
}
</script>

<template>
    <div class="w-full">
      <stripe-pricing-table 
        pricing-table-id="prctbl_1QCsrRHjxr2xtPQSHzgcDxbd" 
        publishable-key="pk_test_51OKbhiHjxr2xtPQSc9OBAlaxpCdsQLQkLYPOaQBe5yXu8kLJwAEZWJNNUzyReYgcLk1D1Vb0qun3ZkQwCHFT9iBJ00QRB3IBtq">
      </stripe-pricing-table>
    </div>
  </template>
  