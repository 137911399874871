<script>
  import { mapState, mapActions } from 'vuex';
  export default {
    name: "Finishing",
    computed: {
        ...mapState('knowledge', ['uploadedFile', 'datasetId', 'crawlResult'])
    },
    methods: {
        ...mapActions('knowledge', ['handleRemoveFile'])
    },
    unmounted() {
        this.handleRemoveFile();
    }
  }
</script>

<template>
    <div class="p-2 md:p-8 flex flex-1 h-full gap-3 flex-col m-0 overflow-auto">
        <div class="flex justify-between">
            <header>
                <h1 class="flex items-center mb-0 text-2xl font-medium text-slate-900 dark:text-slate-100">
                    🎉 Knowledge Created
                </h1>
                <p>We automatically named the Knowledge, you can modify it at any time.</p>
            </header>
            <div>
            <router-link 
                :to="`../view/${datasetId}/document`" 
                class="inline-block bg-woot-500 text-slate-100 px-4 py-2 rounded-md hover:bg-woot-700"
            >
                Go to document
            </router-link>
        </div>
        </div>
        <hr>
        <div>
            <h1>Knowledge Name</h1>
            <p class="w-full rounded-md p-1 max-w-xl">{{ uploadedFile ? uploadedFile.name : crawlResult.data[0].metadata.title }}</p>
        </div>
        <!-- <hr> -->
        <!-- <div>
            <h1>Embedding Completed</h1>
            <div class="flex justify-between items-center bg-green-300 p-2 rounded-md max-w-xl text-slate-800">
              <p class="m-0 p-0">Document 1.pdf</p>
              <p class="m-0 p-0">100%</p>
            </div>
        </div>
        <br> 
        <div class="grid grid-cols-2 text-xs max-w-xl">
            <p>Segmentation rule</p>
            <p class="font-light">Automatic</p>
            <p>Chuncks Length</p>
            <p class="font-light">500</p>
            <p>Text pre-definition</p>
            <p class="font-light">Automatic</p>
        </div> -->
    </div>
</template>