// DahReply
import { useAlert } from 'dashboard/composables';

export const state = {
  account: null,
  status: null,
  url: null,
  knowledgeLimit: null,
  agentLimit: null,
  inboxLimit: null,
  billingUrl: null,
  credits: null,
  words: null,
  topUpUrl: null,
  creditUsed: null,
  topupHistoryArray: [],
  topupCredits: null,
  topupCreditsUsed: null,
};

export const getters = {
  getKnowledgeLimit(state) {
    return state.knowledgeLimit;
  },
  getAgentLimit(state) {
    return state.agentLimit;
  },
  getInboxLimit(state) {
    return state.inboxLimit;
  },
  getSubscriptionStatus(state) {
    return state.status;
  },
  getBillingUrl(state) {
    return state.billingUrl;
  },
  getCredits(state) {
    return state.credits;
  },
  getWordsLimit(state) {
    return state.words;
  },
  getTopUpUrl(state) {
    return state.topUpUrl;
  },
  getCreditUsed(state) {
    return state.creditUsed;
  },
  getTopupHistoryArray(state) {
    return state.topupHistoryArray;
  },
  getTopupCredits(state) {
    return state.topupCredits;
  },
  getTopupCreditsUsed(state) {
    return state.topupCreditsUsed;
  },
};

export const mutations = {
  setKnowledgeLimit(state, knowledgeLimit) {
    state.knowledgeLimit = knowledgeLimit;
  },
  setAgentLimit(state, agentLimit) {
    state.agentLimit = agentLimit;
  },
  setInboxLimit(state, inboxLimit) {
    state.inboxLimit = inboxLimit;
  },
  setSubscriptionStatus(state, status) {
    state.status = status;
  },
  setBillingUrl(state, status) {
    state.billingUrl = status;
  },
  setCreditsLimit(state, credits) {
    state.credits = credits;
  },
  setWordsLimit(state, limit) {
    state.words = limit;
  },
  setTopUpUrl(state, url) {
    state.topUpUrl = url;
  },
  setCreditUsed(state, used) {
    state.creditUsed = used;
  },
  setTopupHistoryArray(state, history) {
    state.topupHistoryArray = history;
  },
  setTopupCredits(state, credits) {
    state.topupCredits = credits;
  },
  setTopupCreditsUsed(state, used) {
    state.topupCreditsUsed = used;
  },
};

export const actions = {
  async fetchSubscription({ rootGetters, commit }, { id } = {}) {
    const apiUrl = process.env.VUE_APP_KNOWLEDGE_API;
    const xApiKey = process.env.VUE_APP_X_API_KEY;
    const accountId = rootGetters.getCurrentAccountId || id;

    if (!accountId) {
      console.error('No account ID provided.');
      useAlert('Account ID is missing. Please try again later.');
      return;
    }

    try {
      const rawResponse = await fetch(
        `${apiUrl}/account/${accountId}/subscription`,
        {
          headers: {
            'X-API-KEY': xApiKey,
          },
        }
      );
      if (!rawResponse.ok) {
        console.error(
          `HTTP Error: ${rawResponse.status} - ${rawResponse.statusText}`
        );
        useAlert(
          'Error fetching subscription plan of user, please try again later.'
        );
        return;
      }

      const response = await rawResponse.json();
      commit('setSubscriptionStatus', response.status);
      commit('setKnowledgeLimit', response.features.datasets);
      commit('setAgentLimit', response.features.agents);
      commit('setInboxLimit', response.features.inboxes);
      commit('setCreditsLimit', response.features.credits);
      commit('setWordsLimit', response.features.knowledge_words);
      commit('setCreditUsed', response.features.credits_used);
      commit('setTopupCredits', response.features.topup_credits);
      commit('setTopupCreditsUsed', response.features.topup_credits_used);
    } catch (err) {
      console.error(err);
      useAlert(
        'Error fetching subscription plan of user, please check your connection or try again later.'
      );
      throw err;
    }
  },
  async fetchStripeSession({ rootGetters, commit }) {
    const api = process.env.VUE_APP_KNOWLEDGE_API;
    const xApiKey = process.env.VUE_APP_X_API_KEY;
    const accountId = rootGetters.getCurrentAccountId;
    const payload = {
      account_id: accountId,
    };

    if (!api || !xApiKey) {
      return;
    }

    try {
      const rawResponse = await fetch(`${api}/stripe/create_portal_session`, {
        method: 'POST',
        headers: {
          'X-API-KEY': xApiKey,
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(payload),
      });

      if (rawResponse.status === 404) {
        useAlert(
          'It seems you have not subscribed to a plan yet. Please choose a plan to proceed.'
        );
        window.location = `/app/accounts/${accountId}/pricing_table`;
      }
      const response = await rawResponse.json();
      commit('setBillingUrl', response.url.replace('https://', ''));
    } catch (err) {
      console.error(err);
      throw err;
    }
  },
  async topupCheckoutSession({ rootGetters, commit }, creditAmount) {
    const api = process.env.VUE_APP_KNOWLEDGE_API;
    const xApiKey = process.env.VUE_APP_X_API_KEY;
    const accountId = rootGetters.getCurrentAccountId;
    const payload = {
      // prettier-ignore
      "account_id": accountId,
      // prettier-ignore
      "credit_amount": creditAmount,
    };

    try {
      const rawResponse = await fetch(`${api}/stripe/topup_checkout_session`, {
        method: 'POST',
        headers: {
          'X-API-KEY': xApiKey,
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(payload),
      });

      if (!rawResponse.ok) {
        throw new Error('Error topping up, please try again later.');
      }

      const response = await rawResponse.json();
      commit('setTopUpUrl', response.url);
    } catch (err) {
      console.error(err);
      throw err;
    }
  },
  async fetchTopupHistory({ commit, rootGetters }) {
    const api = process.env.VUE_APP_KNOWLEDGE_API;
    const xApiKey = process.env.VUE_APP_X_API_KEY;
    const accountId = rootGetters.getCurrentAccountId;
    try {
      const rawResponse = await fetch(
        `${api}/account/${accountId}/top_up_history`,
        {
          headers: {
            'X-API-KEY': xApiKey,
          },
        }
      );

      if (!rawResponse.ok) {
        throw new Error(
          'Error fetching top-up history, please try again later.'
        );
      }

      const response = await rawResponse.json();
      commit('setTopupHistoryArray', response);
    } catch (err) {
      console.error(err);
      throw err;
    }
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
