<script>
  import LoadingState from '../../../../../components/widgets/LoadingState.vue'
  import DragAndDrop from '../../components/DragAndDrop.vue';
  import { mapState, mapGetters, mapActions } from 'vuex';

  export default {
    name: 'view_knowledge_documents',
    components: {
      LoadingState,
      DragAndDrop
    },
    data(){
        return {
            tableHeaders: ['#', 'Filename', 'Words', 'Retrieval Count', 'Upload Time', 'Status', 'Action'],
            isLoading: false,
            isUpload: false,
            datasetId: this.$route.params.id,
            isUploading: false,
            isDeleting: false,
            deleteId: null,
        }
    },
    computed: {
      ...mapState('knowledge', ['uploadedFile', 'tableBody']),
      ...mapGetters({ accountId: 'getCurrentAccountId' }),
    },
    methods: {
      ...mapActions('knowledge', ['handleRemoveFile', 'handleUpload', 'fetchDocuments', 'handleDeleteDocument', 'handleFileUpload']),
      handleCancel(){
        this.handleRemoveFile();
        this.isUpload = false;
      },
      async handleRefresh(){
        this.isLoading = true;
        await this.fetchDocuments(this.datasetId)
        this.isLoading = false;
      },
      async handleUploadLocal(){
        try{
          this.isUploading = true;
          await this.handleUpload(this.datasetId);
          this.isUpload = false;
          this.handleFileUpload(null);
        }catch(err){
          console.error(err);
        }finally{
          this.isUploading(false);
        }
      },
      handleDeleteLocal(id){
        this.isDeleting = true;
        this.deleteId = id;
      },
      cancelDelete(){
        this.isDeleting = false;
        this.deleteId = null;
      },
      async handleConfirmDelete(){
        try{
          await this.handleDeleteDocument({datasetId: this.datasetId, document_id: this.deleteId,});
          this.cancelDelete();
        }catch(err){
          console.error(err);
        }
      }
    },
    mounted(){
      this.handleRefresh();
    }
  }
</script>

<template>
  <div class="p-2 md:p-8 flex flex-1 h-full gap-3 flex-col m-0 overflow-auto">
    <header class="flex justify-between">
      <div>
        <h1 class="flex items-center mb-0 text-2xl font-medium text-slate-900 dark:text-slate-100">Documents</h1>
        <p>All files of the Knowledge are shown here, and the entire Knowledge can be linked to citations or indexed via the Chat plugin.</p>
      </div>
      <div class="flex gap-2">
        <woot-button
          variant="smooth"
          color-scheme="secondary"
          icon="arrow-clockwise"
          @click="handleRefresh()"
        />
        <woot-button
          variant="smooth"
          color-scheme="primary"
          icon="add"
          @click="isUpload = !isUpload"
        >
          Add Documents
        </woot-button>
      </div>
    </header>

    <br>

    <div v-if="isUpload" class="fixed top-0 left-0 h-[100vh] w-[100vw] bg-slate-100/50" @click="handleCancel"></div>

    <div 
      v-if="isUpload" 
      class="z-50 bg-slate-100 dark:bg-slate-900 rounded-md flex flex-col items-center justify-center p-6 absolute top-2/4 left-2/4 -translate-x-2/4 -translate-y-2/4">
      <DragAndDrop />
      <div class="w-full flex justify-between mt-8">
        <woot-button
          variant="smooth"
          color-scheme="secondary"
          icon="file-upload" 
          @click="() => handleUploadLocal()"
          :disabled="!uploadedFile"
        >
          <span v-html="isUploading ? '<span class=\'animate-pulse\'>Loading...</span>' : 'Upload'"></span>
        </woot-button>
        <woot-button
          variant="smooth"
          color-scheme="alert"
          icon="dismiss-circle" 
          @click="handleCancel"
        >
          Cancel
        </woot-button>
      </div>
    </div>
    

    <LoadingState v-if="isLoading"/>

    <div v-if="isDeleting" class="z-40 fixed top-0 left-0 h-[100vh] w-[100vw] bg-slate-100/50" @click="cancelDelete()"></div>

      <div 
        v-if="isDeleting" 
        class="z-50 md:min-w-[300px] fixed top-2/4 left-2/4 -translate-x-2/4 -translate-y-2/4 p-4 rounded-md bg-slate-100 dark:bg-slate-900"
      >
        <h1 class="mb-8">Confirm delete document?</h1>
        <div class="w-full flex justify-between mt-8">
        <woot-button
          variant="smooth"
          color-scheme="secondary"
          icon="dismiss-circle" 
          @click="cancelDelete()"
        >
          Cancel
        </woot-button>
        <woot-button
          variant="smooth"
          color-scheme="alert"
          icon="file-upload" 
          @click="() => handleConfirmDelete()"
        >
          Delete
        </woot-button>
      </div>
      </div>

    <div v-if="!isLoading">
      <table class="text-slate-800 dark:text-slate-50 w-full">
        <thead>
          <tr class="border-y-2 border-slate-50">
            <th v-for="(header, index) in tableHeaders" :key="index" class="border-y-2 border-slate-800 dark:border-slate-50 text-start">
              {{ header }}
            </th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(row, index) in tableBody" :key="index" class="border-y-2 py-4 leading-relaxed font-light border-slate-800 dark:border-slate-50 cursor-pointer hover:bg-slate-200 dark:hover:bg-slate-700">
            <td v-for="(value, key) in row" :key="key" class="border-y-2 border-slate-800 dark:border-slate-50 py-4">
              <woot-button  
                variant="smooth"
                color-scheme="secondary"
                icon="delete" 
                v-if="key === 'id'" 
                @click="handleDeleteLocal(value)"
              />
              <span v-else :class="{'flex gap-2 items-center' : key==='status'}">
                <div 
                  v-if="key === 'status'" 
                  class="w-3 h-3 rounded-full"
                  :class="{
                    'bg-green-500' : value === 'available',
                    'bg-yellow-500' : value === 'indexing',
                    'bg-gray-500' : value === 'unavailable',
                    'bg-yellow-300' : value === 'queuing',
                    'bg-red-500' : value === 'error',
                  }"
                ></div>
                {{ value }}
              </span>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>