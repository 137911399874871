import { frontendURL } from "../../../../helper/URLHelper";
import ViewKnowledge from "./index.vue";
import Document from "./documents/Document.vue";
import Testing from "./testing/Testing.vue";
import Settings from "./settings/Settings.vue";

export default {
    routes: [
      {
        path: frontendURL('accounts/:accountId/knowledge/view/:id'),  
        name: 'view_knowledge',
        component: ViewKnowledge,
        meta: {
          permissions: ['administrator', 'agent'],
        },
        redirect: { name: 'view_knowledge_documents' },
        children: [
          {
            path: 'document',
            name: 'view_knowledge_documents',
            component: Document,
            meta: {
              permissions: ['administrator', 'agent'],
            },
          },
          {
            path: 'testing',
            name: 'view_knowledge_testing',
            component: Testing,
            meta: {
              permissions: ['administrator', 'agent'],
            },
          },
          {
            path: 'settings',
            name: 'view_knowledge_settings',
            component: Settings,
            meta: {
              permissions: ['administrator', 'agent'],
            },
          }
        ]
      },
    ],
};