<script>
  import IndexMode from '../../components/IndexMode.vue';
  import { mapState, mapActions, mapGetters } from 'vuex';
  import { useAlert } from 'dashboard/composables';

  export default {
    name: "Processing",
    components: {
      IndexMode,
    },
    computed: {
      ...mapState('knowledge', ['uploadedFile', 'datasetId', 'crawlResult']),
      ...mapGetters({ accountId: 'getCurrentAccountId' })
    },
    data(){
      return {
        chunckSettings: "automatic",
        indexingTechnique: 'high_quality',
        isLoading: false,
      }
    },
    mounted(){
      if(!this.uploadedFile && !this.crawlResult){
        this.$router.push({name: 'add_knowledge_upload'})
      }
    },
    methods: {
      ...mapActions('knowledge', ['convertToFile', 'createKnowledgeBase', 'uploadDocument']),
      setChuckSettings(value) {
        this.chunckSettings = value;
      },
      async uploadFile() {
        this.isLoading = true;
        try{
          if(this.uploadedFile){
            await this.createKnowledgeBase(); 
            await this.uploadDocument();
          }
          else if(this.crawlResult){
            await this.createKnowledgeBase(); 
            await this.convertToFile();
          } else {
            throw new Error('No file is uploaded.')
          }
          this.$router.push({name: 'add_knowledge_finishing'})
        } catch(err){
          console.error(err);
          useAlert("Error creating knowledge base, please try again later.")
        } finally {
          this.isLoading = false;
        }
      },
    }
  }
</script>

<template>
    <div class="p-2 md:p-8 flex flex-1 h-full gap-3 flex-col m-0 overflow-auto">
      <div class="flex justify-between">
        <h1 class="flex items-center mb-0 text-2xl font-medium text-slate-900 dark:text-slate-100">
          Text Preprocessing
        </h1>
        <div class="flex gap-2">
          <router-link to="upload" class="inline-block bg-woot-500 text-slate-100 px-4 py-2 rounded-md hover:bg-woot-700">Previous</router-link>
          <button v-if="!isLoading" @click="uploadFile" class="inline-block bg-woot-500 text-slate-100 px-4 py-2 rounded-md hover:bg-woot-700">
            Next
          </button>
          <button v-else class="inline-block bg-woot-500 text-slate-100 px-4 py-2 rounded-md hover:bg-woot-700">
            <span class="animate-pulse">Loading...</span>
          </button>
        </div>
      </div>

      <div class="my-8">
          <h1 class="mb-4">Chunk settings</h1>
          <label 
            class="flex justify-between border-2 p-6 rounded-md cursor-pointer hover:bg-slate-300 dark:hover:bg-slate-700"
            @click="setChuckSettings('automatic')"
            :class="{'border-woot-500' : chunckSettings === 'automatic', 'border-slate-200' : chunckSettings !== 'automatic' }"  
          >
            <div>
              <h1>Automatic</h1>
              <p>Automatically set chunk and preprocessing rules. Unfamiliar users are recommended to select this.</p>
            </div>
            <input name="test" value="1" type="radio" checked />
          </label>

          <!-- <br>

          <label 
            class="flex flex-col border-2 p-6 rounded-md cursor-pointer hover:bg-slate-300 dark:hover:bg-slate-700"
            @click="setChuckSettings('custom')"
            :class="{'border-woot-500' : chunckSettings === 'custom', 'border-slate-200' : chunckSettings !== 'custom' }"
          >  
            <div class="flex justify-between">
              <div>
                <h1>Custom</h1>
                <p>Customize chunks rules, chunks length, and preprocessing rules, etc.</p>
              </div>
              <input name="test" value="2" type="radio">
            </div>

            <div v-if="chunckSettings === 'custom'" class="flex flex-col">
              <hr>
              <label>Delimiter</label>
              <input type="text" class="rounded-md" />
              <br>
              <label>Maximum Chunck Length</label>
              <input type="number" class="rounded-md" />
              <br>
              <label>Chunck Overlap</label>
              <input type="number" class="rounded-md" />
              <br>
              <label>Text Preprocessing Rules</label>
              <br>
              <span class="flex items-center gap-2">
                <label>Replace consecutive spaces, newlines and tabs</label>
                <input type="checkbox" name="rules" value="Replace consecutive spaces, newlines and tabs" />
              </span>
              <span class="flex items-center gap-2">
                <label>Delete all URLs and email addresses</label>
                <input type="checkbox" name="rules" value="Delete all URLs and email addresses" />
              </span>
              <br>
              <span class="flex gap-4 items-center">
                <button class="border-2 border-slate-100 px-4 py-2 rounded-md hover:bg-slate-100 hover:text-black">Confirm and review</button>
                <button class="border-2 border-slate-100 px-4 py-2 rounded-md hover:bg-slate-100 hover:text-black">Reset</button>
              </span>
            </div>
          </label> -->
      </div>

      <!-- <IndexMode /> -->

      <!-- <div class="my-8 flex p-6 gap-2 rounded-md">
        <div>
          <p>Preprocess documents</p>
          <p class="text-xl">{{ uploadedFile.name.slice(0, 18) + '...' }}</p>
        </div>
        <div class="border-l-2 pl-4 border-white">
          <p>Estimated chuncks</p>
          <p class="text-sm">Calculating...</p>
        </div>
      </div> -->

      <div v-if="crawlResult && crawlResult.data && crawlResult.data.length">
        <div
          v-for="(result, index) in crawlResult.data"
          :key="index"
          class="border-2 rounded-md mb-4 p-4"
        >
          <h1>{{ result.metadata.title }}</h1>
          <p>{{ result.metadata.description }}</p>
          <hr>
          <p class="line-clamp-3">{{ result.markdown }}</p>
        </div>
      </div>
    </div>
</template>