<script>
  export default {
    name: 'view_knowledge_testing',
  }
</script>

<template>
  <div class="p-2 md:p-8 grid grid-cols-2 h-full w-full gap-3 flex-col m-0 overflow-auto">
    <div class="flex flex-col gap-4">
      <div>
          <header>
            <h1 class="flex items-center mb-0 text-2xl font-medium text-slate-900 dark:text-slate-100">
              Retrieval Testing
            </h1>
            <p>Test the hitting effect of the Knowledge based on the given query text.</p>
          </header>
          <br>
          <label>
              <span class="flex justify-between my-2 items-center">
                  <p>Source Text</p>
                  <button class="inline-block bg-woot-500 text-slate-100 px-4 py-2 rounded-md hover:bg-woot-700">
                    Vector Search
                  </button>
              </span>
              <textarea class="w-full min-h-[300px] rounded-md"></textarea>
          </label>
      </div>
      <div>
          <h1>Recents</h1>
          <br>
          <div class="bg-slate-200 dark:bg-slate-800 rounded-md p-4">
              <p>No recent queries here...</p>
          </div>
      </div>
    </div>
    <div class="border-l-2 ps-5 ms-5">
        <h1>The results will show up here.</h1>
    </div>
  </div>
</template>