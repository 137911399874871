// DahReply
const xApiKey = process.env.VUE_APP_X_API_KEY;
const apiUrl = process.env.VUE_APP_KNOWLEDGE_API;

export const state = {
  uploadedFile: null,
  knowledgeData: null,
  indexing_technique: 'high_quality',
  process_rule: {},
  isUploaded: false,
  uuid: null,
  datasetId: null,
  crawlId: null,
  crawlResult: {},
  knowledgeList: [],
  tableBody: [],
  activeKnowledgeList: [],
  restrictionStatus: null,
};

export const getters = {
  getUploadedFile(state) {
    return state.uploadedFile;
  },
  isFileUploaded(state) {
    return state.isUploaded;
  },
  getCrawlId(state) {
    return state.crawlId;
  },
  getKnowledgeList(state) {
    return state.knowledgeList;
  },
  getTableBody(state) {
    return state.tableBody;
  },
  getActiveKnowledgeList(state) {
    return state.activeKnowledgeList;
  },
};

export const mutations = {
  setIsUploaded(state, status) {
    state.isUploaded = status;
  },
  setUUID(state, uuid) {
    state.uuid = uuid;
  },
  setUploadedFile(state, file) {
    state.uploadedFile = file;
    state.isUploaded = true;
  },
  removeUploadedFile(state) {
    state.uploadedFile = null;
    state.isUploaded = false;
  },
  removeCrawlResult(state){
    state.crawlResult = {};
    state.isUploaded = false;
  },
  setDatasetId(state, id) {
    state.datasetId = id;
  },
  setCrawlId(state, id) {
    state.crawlId = id;
  },
  addUploadedFile(state, file) {
    if (!state.uploadedFile) {
      state.uploadedFile = [file];
    } else {
      state.uploadedFile = [...state.uploadedFile, file];
    }
  },
  setCrawlResult(state, result) {
    state.crawlResult = result;
  },
  updateCrawlResult(state, result){
    state.crawlResult.data = result;
  },
  setKnowledgeList(state, knowledgeList) {
    state.knowledgeList = knowledgeList;
  },
  setTableBody(state, data) {
    state.tableBody = [...data];
  },
  setActiveKnowledgeList(state, activeKnowledgeList) {
    state.activeKnowledgeList = activeKnowledgeList;
  },
  setRestrictionStatus(state, status) {
    state.restrictionStatus = status;
  }
};

import { useAlert } from 'dashboard/composables';

export const actions = {
  async fetchKnowledgeList({ rootGetters, commit }) {
    const xApiKey = process.env.VUE_APP_X_API_KEY;
    const apiUrl = process.env.VUE_APP_KNOWLEDGE_API;
    const accountId = rootGetters.getCurrentAccountId;

    try {
      const rawResponse = await fetch(
        `${apiUrl}/chatbot/accounts/${accountId}/datasets`,
        {
          method: 'GET',
          headers: {
            'X-API-KEY': xApiKey,
          },
        }
      );

      if (!rawResponse.ok) {
        throw new Error('Failed to fetch knowledge datasets');
      }

      const response = await rawResponse.json();
      commit('setKnowledgeList', response.data);
    } catch (error) {
      console.error('Error fetching knowledge datasets:', error);
      throw err;
    }
  },
  async deleteKnowledge({ rootGetters, dispatch }, { dataset_id }) {
    const xApiKey = process.env.VUE_APP_X_API_KEY;
    const apiUrl = process.env.VUE_APP_KNOWLEDGE_API;
    const accountId = rootGetters.getCurrentAccountId;
    try {
      let response = await fetch(
        `${apiUrl}/chatbot/accounts/${accountId}/datasets/${dataset_id}`,
        {
          method: 'DELETE',
          headers: {
            'X-API-KEY': xApiKey,
          },
        }
      );
      if (!response.ok) {
        throw new Error('Error deleting Knowledge, please try again later.');
      }
      dispatch('fetchKnowledgeList');
    } catch (err) {
      console.error(err);
      throw err;
    }
  },
  async createKnowledgeBase({ state, rootGetters, commit }) {
    const xApiKey = process.env.VUE_APP_X_API_KEY;
    const apiUrl = process.env.VUE_APP_KNOWLEDGE_API;
    const accountId = rootGetters.getCurrentAccountId;
    const title = state.uploadedFile
      ? state.uploadedFile.name
      : state.crawlResult.data[0].metadata.title;

    try {
      const url = `${apiUrl}/chatbot/accounts/${accountId}/datasets`;
      const rawResponse = await fetch(url, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'X-API-KEY': xApiKey,
        },
        body: JSON.stringify({
          // Max characters are 17
          name: `${title.slice(0, 17)}...`,
        }),
      });

      if (!rawResponse.ok) {
        throw new Error(`Error creating knowledge base.`);
      }
      const response = await rawResponse.json();
      console.log(response);
      commit('setDatasetId', response.dataset_id);
    } catch (err) {
      console.error(err);
      throw err;
    }
  },
  async uploadDocument({ state, rootGetters }) {
    if (!state.uploadedFile || !state.datasetId) {
      return;
    }

    const xApiKey = process.env.VUE_APP_X_API_KEY;
    const apiUrl = process.env.VUE_APP_KNOWLEDGE_API;
    const accountId = rootGetters.getCurrentAccountId;
    const formData = new FormData();
    formData.append('file', state.uploadedFile);

    try {
      const response = await fetch(
        `${apiUrl}/chatbot/account/${accountId}/datasets/${state.datasetId}/documents`,
        {
          method: 'POST',
          body: formData,
          headers: {
            'X-API-KEY': xApiKey,
          },
        }
      );

      if (!response.ok) {
        throw new Error(`Error uploading document: ${response.statusText}`);
      }
    } catch (err) {
      console.error(err);
      throw err;
    }
  },
  handleFileUpload({ commit }, file) {
    commit('setUploadedFile', file);
  },
  async handleCrawl({ commit, dispatch }, payload) {
    const {
      url,
      limit,
      maxDepth,
      useSiteMap,
      onlyMainContent,
      includedPath,
      excludedPath,
    } = payload;

    try {
      const token = process.env.VUE_APP_FIRECRAWL_API_KEY;
      const apiUrl = process.env.VUE_APP_FIRECRAWL_URL;
      const body = {
        url: url,
        excludePaths: [excludedPath || ''],
        includePaths: [includedPath || ''],
        maxDepth: Number(maxDepth),
        ignoreSitemap: !useSiteMap,
        limit: Number(limit),
        scrapeOptions: {
          formats: ['markdown'],
          includeTags: [''],
          excludeTags: [''],
          onlyMainContent: onlyMainContent,
          removeBase64Images: true,
          waitFor: 1000,
        },
      };

      const options = {
        method: 'POST',
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(body),
      };

      const rawResponse = await fetch(`${apiUrl}/v1/crawl`, options);

      if (rawResponse.ok) {
        const response = await rawResponse.json();
        // await dispatch('processCrawlResults', response.id);
        await dispatch('processCrawlResults', response.url);
      } else {
        const errorResponse = await rawResponse.json();
        console.error(
          'Error:',
          errorResponse.message || 'Error crawling the link.'
        );
      }
    } catch (err) {
      console.error('Error:', err.message || err);
      throw err;
    }
  },
  /* async processCrawlResults({ commit, dispatch }, id) {
    try {
      const token = process.env.VUE_APP_FIRECRAWL_API_KEY;
      const url = process.env.VUE_APP_FIRECRAWL_URL;

      const options = {
        method: 'GET',
        headers: { Authorization: `Bearer ${token}` },
      };

      const rawResponse = await fetch(`${url}/v1/crawl/${id}`, options);

      if (rawResponse.ok) {
        const response = await rawResponse.json();

        if (response.total === 0) {
          throw new Error('Nothing to scrape at the website.');
        }

        if (response.status !== 'completed') {
          setTimeout(() => dispatch('processCrawlResults', id), 1000);
        }

        if (response.status === 'completed') {
          commit('setCrawlResult', response);
          commit('setIsUploaded', true);
        }
      }
    } catch (err) {
      throw err;
    }
  }, */
  async processCrawlResults({ commit, dispatch }, url) {
    try{
      const rawResponse = await fetch(url);

      if (!rawResponse.ok) {
        throw new Error('Error fetching crawl results');
      }

      const response = await rawResponse.json();

      if (!response.success){
        throw new Error('Error fetching crawl results');
      }

      if (response.status === 'completed') {
        commit('setCrawlResult', response);
        commit('setIsUploaded', true);
      }

      if (response.status == 'scraping') {
        setTimeout(() => dispatch('processCrawlResults', url), 1000);
      }
    } catch(err) {
      throw err;
    }
  },
  async convertToFile({ dispatch, state }) {
    // Use a loop to ensure asynchronous dispatches are handled one by one
    for (const result of state.crawlResult.data) {
      const blob = new Blob([result.markdown], { type: 'text/plain' });
      const sanitizedTitle = result.metadata.title.replace(
        /[\/\\:*?"<>|]/g,
        ''
      );
      const file = new File([blob], `${sanitizedTitle}.txt`, {
        type: 'text/plain',
      });

      await dispatch('uploadFile', file);
    }
  },
  handleUpdateCrawlResult({commit}, result){
    commit('updateCrawlResult', result);
  },
  async uploadFile({ rootGetters }, file) {
    const formData = new FormData();
    formData.append('file', file);

    try {
      const apiUrl = process.env.VUE_APP_KNOWLEDGE_API;
      const xApiKey = process.env.VUE_APP_X_API_KEY;
      const accountId = rootGetters.getCurrentAccountId;
      const response = await fetch(
        `${apiUrl}/chatbot/account/${accountId}/datasets/${state.datasetId}/documents`,
        {
          method: 'POST',
          headers: {
            'X-API-KEY': xApiKey,
          },
          body: formData,
        }
      );

      if (!response.ok) {
        throw new Error(`Error uploading document: ${response.statusText}`);
      }
    } catch (err) {
      console.error(err);
      throw err;
    }
  },
  handleDrop({ dispatch }, event) {
    event.preventDefault();
    const files = event.dataTransfer.files;
    dispatch('processFiles', files);
  },
  processFiles({ commit }, files) {
    const validFileTypes = [
      'text/plain', // .txt
      'text/markdown', // .md
      'application/pdf', // .pdf
      'text/html', // .html, .htm
      'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet', // .xlsx
      'application/vnd.ms-excel', // .xls
      'application/vnd.openxmlformats-officedocument.wordprocessingml.document', // .docx
      'text/csv', // .csv
      'application/vnd.ms-powerpoint', // .ppt
      'application/vnd.openxmlformats-officedocument.presentationml.presentation', // .pptx
      'application/xml', // .xml
      'application/epub+zip', // .epub
      'message/rfc822', // .eml
      'application/vnd.ms-outlook', // .msg
    ];
    const maxFileSize = 15 * 1024 * 1024;

    const validFiles = Array.from(files).filter(file => {
      return validFileTypes.includes(file.type) && file.size <= maxFileSize;
    });

    if (validFiles.length !== files.length) {
      alert('Some files were not accepted due to invalid type or size.');
    }

    // Commit the valid files to the state
    if (validFiles.length > 0) {
      commit('setUploadedFile', validFiles[0]); // Assuming only one file will be handled
      commit('setIsUploaded', true);
    }
  },
  updateIsUploaded({ commit }, status) {
    commit('setIsUploaded', status);
  },
  updateDatasetId({ commit }, id) {
    commit('setDatasetId', id);
  },
  handleRemoveFile({ commit }) {
    commit('removeUploadedFile');
    commit('removeCrawlResult');
    commit('setDatasetId', null);
  },
  async fetchDocuments({ commit }, datasetId) {
    const xApiKey = process.env.VUE_APP_X_API_KEY;
    const apiUrl = process.env.VUE_APP_KNOWLEDGE_API;

    try {
      const response = await fetch(
        `${apiUrl}/chatbot/datasets/${datasetId}/documents?page=1&limit=10`,
        {
          method: 'GET',
          headers: {
            'X-API-KEY': xApiKey,
          },
        }
      );

      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }

      const json = await response.json();
      const data = json.data.map((obj, index) => {
        return {
          index: index + 1,
          fileName: obj.name,
          wordCount: obj.word_count,
          retrievalCount: obj.hit_count,
          uploadTime: new Date(obj.created_at * 1000)
            .toISOString()
            .slice(0, 19)
            .replace('T', ' '),
          status: obj.display_status,
          id: obj.id,
        };
      });
      commit('setTableBody', data);
    } catch (err) {
      console.error(err);
      throw err;
    }
  },
  async handleUpload({ rootGetters, state, dispatch }, datasetId) {
    const xApiKey = process.env.VUE_APP_X_API_KEY;
    const apiUrl = process.env.VUE_APP_KNOWLEDGE_API;
    const accountId = rootGetters.getCurrentAccountId;
    const formData = new FormData();

    if (!state.uploadedFile) {
      console.error('No file selected.');
      return;
    }

    formData.append('file', state.uploadedFile);

    try {
      const rawResponse = await fetch(
        `${apiUrl}/chatbot/account/${accountId}/datasets/${datasetId}/documents`,
        {
          method: 'POST',
          body: formData,
          headers: {
            'X-API-KEY': xApiKey,
          },
        }
      );

      if (!rawResponse.ok) {
        useAlert('Error uploading document.');
        throw new Error(`Error uploading document.`);
      }

      dispatch('fetchDocuments', datasetId);
    } catch (err) {
      console.error(err);
      throw err;
    }
  },
  async handleDeleteDocument({ dispatch }, { datasetId, document_id }) {
    const xApiKey = process.env.VUE_APP_X_API_KEY;
    const apiUrl = process.env.VUE_APP_KNOWLEDGE_API;

    try {
      const response = await fetch(
        `${apiUrl}/chatbot/datasets/${datasetId}/documents/${document_id}`,
        {
          method: 'DELETE',
          headers: {
            'X-API-KEY': xApiKey,
          },
        }
      );

      if (!response.ok) {
        useAlert('Error uploading document.');
        throw new Error('Error deleting document.');
      }

      dispatch('fetchDocuments', datasetId);
    } catch (err) {
      console.error(err);
      throw err;
    }
  },
  async fetchActiveKnowledge({ rootGetters, commit }) {
    const accountId = rootGetters.getCurrentAccountId;

    try {
      const rawResponse = await fetch(
        `${apiUrl}/subscription/${accountId}/active_datasets`,
        {
          headers: {
            'X-API-KEY': xApiKey,
          },
        }
      );

      if (!rawResponse.ok) {
        const error = new Error('Error fetching active knowledge.');
        error.status = rawResponse.status;
        throw error;
      }

      const response = await rawResponse.json();
      commit('setRestrictionStatus', response.status);
      commit('setActiveKnowledgeList', response.active_knowledges);
    } catch (err) {
      console.error(err);
      throw err;
    }
  },
  async updateActiveKnowledge({ rootGetters }, idArray) {
    // prettier-ignore
    const payload = { "ids": idArray };
    const accountId = rootGetters.getCurrentAccountId;
    try {
      const rawResponse = await fetch(
        `${apiUrl}/subscription/${accountId}/active_datasets`,
        {
          method: 'POST',
          headers: {
            'X-API-KEY': xApiKey,
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(payload),
        }
      );

      if (!rawResponse.ok) {
        const error = new Error('Error updating active knowledge.');
        error.status = rawResponse.status;
        throw error;
      }
    } catch (err) {
      console.error(err);
      throw err;
    }
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
