<script>
import Logo from './Logo.vue';
import PrimaryNavItem from './PrimaryNavItem.vue';
import OptionsMenu from './OptionsMenu.vue';
import AgentDetails from './AgentDetails.vue';
import NotificationBell from './NotificationBell.vue';
import wootConstants from 'dashboard/constants/globals';
import { frontendURL } from 'dashboard/helper/URLHelper';
import { ACCOUNT_EVENTS } from '../../../helper/AnalyticsHelper/events';
import TicketNavItem from './TicketNavItem.vue';
import { mapActions, mapGetters } from 'vuex';

export default {
  components: {
    Logo,
    PrimaryNavItem,
    OptionsMenu,
    AgentDetails,
    NotificationBell,
    TicketNavItem,
  },
  props: {
    isACustomBrandedInstance: {
      type: Boolean,
      default: false,
    },
    logoSource: {
      type: String,
      default: '',
    },
    installationName: {
      type: String,
      default: '',
    },
    accountId: {
      type: Number,
      default: 0,
    },
    menuItems: {
      type: Array,
      default: () => [],
    },
    activeMenuItem: {
      type: String,
      default: '',
    },
  },
  computed:{
    ...mapGetters('stripe', ['getBillingUrl']),
  },
  data() {
    return {
      helpDocsURL: wootConstants.DOCS_URL,
      showOptionsMenu: false,
    };
  },
  methods: {
    ...mapActions('stripe', ['fetchStripeSession']),
    frontendURL,
    toggleOptions() {
      this.showOptionsMenu = !this.showOptionsMenu;
    },
    toggleAccountModal() {
      this.$emit('toggleAccounts');
    },
    toggleSupportChatWindow() {
      window.$chatwoot.toggle();
    },
    openNotificationPanel() {
      this.$track(ACCOUNT_EVENTS.OPENED_NOTIFICATIONS);
      this.$emit('openNotificationPanel');
    },
    async fetchBillingUrl(){
      try{
        await this.fetchStripeSession();
        window.location.href = `//${this.getBillingUrl}`;
      } catch(err){
        console.error(err);
        this.$router.push({ name: 'pricing_table' });
      }
    }
  },
  mounted(){
    this.fetchStripeSession();
  }
};
</script>

<template>
  <div
    class="flex flex-col justify-between w-16 h-full bg-white border-r dark:bg-slate-900 border-slate-50 dark:border-slate-800/50 rtl:border-l rtl:border-r-0"
  >
    <div class="flex flex-col items-center">
      <Logo
        :source="logoSource"
        :name="installationName"
        :account-id="accountId"
        class="m-4 mb-5"
      />
      <PrimaryNavItem
        v-for="menuItem in menuItems"
        :key="menuItem.toState"
        :icon="menuItem.icon"
        :name="menuItem.label"
        :to="menuItem.toState"
        :is-child-menu-active="menuItem.key === activeMenuItem"
      />
    </div>
    <div class="flex flex-col items-center justify-end pb-2">
      <!-- <PrimaryNavItem
        v-if="!isACustomBrandedInstance"
        icon="book-open-globe"
        name="DOCS"
        open-in-new-page
        :to="helpDocsURL"
      /> -->
      <!-- <PrimaryNavItem
        icon="credit-card-person"
        name="BILLING"
        open-in-new-page
        @click="fetchBillingUrl"
      /> -->

      <button 
        @click="fetchBillingUrl"
        v-tooltip.right="$t(`SIDEBAR.BILLING`)"
        class="text-slate-700 dark:text-slate-100 w-10 h-10 my-2 flex items-center justify-center rounded-lg hover:bg-slate-25 dark:hover:bg-slate-700 dark:hover:text-slate-100 hover:text-slate-600 relative"
      >
        <fluent-icon
          icon="credit-card-person"
        />
        <span class="sr-only">BILLING</span>
      </button>

      <!-- <TicketNavItem /> -->
      <NotificationBell @openNotificationPanel="openNotificationPanel" />
      <AgentDetails @toggleMenu="toggleOptions" />
      <OptionsMenu
        :show="showOptionsMenu"
        @toggleAccounts="toggleAccountModal"
        @showSupportChatWindow="toggleSupportChatWindow"
        @openKeyShortcutModal="$emit('openKeyShortcutModal')"
        @close="toggleOptions"
      />
    </div>
  </div>
</template>
