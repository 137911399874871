<script>
import { mapActions, mapState } from 'vuex';
import LoadingState from '../../../../components/widgets/LoadingState.vue';

export default{
  name: "FireCrawl",
  computed: {
    ...mapState('knowledge', ['crawlResult', 'isUploaded'])
  },
  components: {
    LoadingState,
  },
  data(){
    return{
      url: null,
      limit: 10,
      maxDepth: 2,
      useSiteMap: true,
      onlyMainContent: true,
      includedPath: "",
      excludedPath: "",
      isLoading: false,
      selectedResult: [],
    }
  },
  methods: {
    ...mapActions(
      'knowledge', ['handleCrawl', 'handleRemoveFile']
    ),
    async waitForCondition(conditionFn, interval = 100) {
      return new Promise((resolve) => {
        const check = setInterval(() => {
          if (conditionFn()) {
            clearInterval(check);
            resolve();
          }
        }, interval);
      });
    },
    async startCrawl() {
      if (!this.url) {
        alert('Please enter a valid URL.');
        return;
      }
      if(this.isUploaded){
        this.handleRemoveFile();
      }
      try {
        this.isLoading = true;
        await this.handleCrawl({
          url: this.url,
          limit: this.limit,
          maxDepth: this.maxDepth,
          useSiteMap: this.useSiteMap,
          onlyMainContent: this.onlyMainContent,
          includedPath: this.includedPath,
          excludedPath: this.excludedPath,
        });
      } catch (error) {
        console.error('Crawl failed:', error);
        alert('Crawl failed. Please try again later.');
        this.isLoading = false;
      } finally {
        await this.waitForCondition(() => this.isUploaded === true);
        this.isLoading = false;
      }
    },
  },
}
</script>

<template>
<div>
  <div class="my-4 p-6 rounded-md bg-slate-200 dark:bg-slate-800">
    <span class="flex gap-2">
      <input
        id="url-input"
        class="p-0 m-0"
        type="text"
        v-model="url"
        placeholder="Enter website URL"
        aria-label="Website URL"
      />
      <woot-button 
        @click="startCrawl"
        v-if="!isLoading"
      >
        Next
      </woot-button>
      <woot-button v-else class="flex items-center justify-center">
        <svg class="animate-spin h-5 w-5 text-gray-500" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
          <circle
            class="opacity-25"
            cx="12"
            cy="12"
            r="10"
            stroke="currentColor"
            stroke-width="4"
          ></circle>
          <circle
            class="opacity-75"
            cx="12"
            cy="12"
            r="10"
            stroke="currentColor"
            stroke-width="4"
            stroke-dasharray="80"
            stroke-dashoffset="60"
          ></circle>
        </svg>
      </woot-button>
    </span>
    <hr>
    <h1>Options</h1>
    <br>
    <label class="flex gap-2 items-center">
      <input type="checkbox" v-model="useSiteMap" />
      <p class="m-0 p-0">Crawl sub-pages</p>
    </label>
    <br>
    <div class="grid grid-cols-2 grid-rows-2 gap-2">
      <label class="flex flex-col">
        <p>Limit</p>
        <input type="number" v-model="limit" />
      </label>
      <label class="flex flex-col">
        <p>Max depth</p>
        <input type="number" v-model="maxDepth" />
      </label>
      <label class="flex flex-col">
        <p>Excluded paths</p>
        <input type="text" placeholder="blog/*, about/*" v-model="excludedPath" />
      </label>
      <label class="flex flex-col">
        <p>Include only paths</p>
        <input type="text" placeholder="articles/*" v-model="includedPath" />
      </label>
    </div>
    <label class="flex gap-2 items-center">
      <input type="checkbox" v-model="onlyMainContent" />
      <p class="m-0 p-0">Extract only main content (no headers, navs, footers, etc.)</p>
    </label>
  </div>
</div>

</template>