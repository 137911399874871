<script>
    import LoadingState from '../../../components/widgets/LoadingState.vue';
    import { mapGetters, mapActions } from 'vuex';

    export default {
      name: 'Knowledge',
      data() {
        return {
          isLoading: false,
          visible: null,
          isSelectingActive: false, 
          selectedKnowledgeList: [], 
          isDeleting: false,
          deleteTarget: null,
          isRerouting: false,
        };
      },
      components: {
        LoadingState
      },
      computed: {
        ...mapGetters({ accountId: 'getCurrentAccountId' }),
        ...mapGetters('stripe', ['getKnowledgeLimit', 'getInboxLimit']),
        ...mapGetters('knowledge', ['getKnowledgeList', 'getActiveKnowledgeList']),
        ...mapGetters('inboxes', ['getInboxes', 'getActiveInboxesIds']),
        activeKnowledgeList(){
          return this.getActiveKnowledgeList;
        },
        datasetLimit(){
          return this.getKnowledgeLimit;
        },
        knowledgeList(){
          return this.getKnowledgeList;
        },
        goToInbox(){
          return (this.getInboxes.length > this.getInboxLimit && this.getActiveInboxesIds === 0);
        },
        inboxList(){
          return this.getInboxes;
        },
        filteredList() {
          const isLargeDataset = this.knowledgeList.length > this.datasetLimit;

          const list = this.knowledgeList.map((knowledge) => {
            return {
              ...knowledge,
              active: isLargeDataset 
                ? (Array.isArray(this.activeKnowledgeList) && this.activeKnowledgeList.includes(knowledge.id))
                : true,
            };
          });

          return list.sort((a, b) => (b.active ? 1 : 0) - (a.active ? 1 : 0));
        }
      },
      methods: {
        ...mapActions('stripe', ['fetchSubscription']),
        ...mapActions('knowledge', ['fetchKnowledgeList', 'deleteKnowledge', 'fetchActiveKnowledge', 'updateActiveKnowledge']),
        handleAddKnowledge(){
          this.$router.push({name: 'add_knowledge'});
        },
        handleDeleteKnowledge(target){
          this.deleteTarget = target;
          this.isDeleting = true;
          this.visible = null;
        },
        async handleConfirmDelete(){
          try{
            await this.deleteKnowledge({dataset_id: this.deleteTarget.id});
            this.cancelDelete();
          } catch(err){
            console.error(err);
          } finally {
            this.visible = null;
          }
        },
        cancelDelete(){
          this.isDeleting = false;
          this.deleteTarget = null;
        },
        async handleSelectActive(){
          const payload = this.selectedKnowledgeList
          try{
            await this.updateActiveKnowledge(payload);
            this.isSelectingActive = false;
            this.selectedKnowledgeList = [];
            await this.refreshPage();
          } catch(err){
            console.error(err);
          }
        },
        async refreshPage(){
          try{
          this.isLoading = true;
          await this.fetchKnowledgeList();
          await this.fetchSubscription();
          
          if(this.knowledgeList.length > this.datasetLimit){
            await this.fetchActiveKnowledge();
            if(!this.activeKnowledgeList || this.activeKnowledgeList.length == 0){
              this.isSelectingActive = true;
            }
          }

        } catch (err){
          console.error(err);
        } finally {
          this.isLoading = false;
        }
        }
      },
      mounted() {
        this.isRerouting = false;
        if(this.goToInbox){
          this.isRerouting = true;
        }
        this.refreshPage();
      },
    };
</script>

<template>
    <div class="p-2 md:p-8 flex flex-1 h-full gap-3 flex-col m-0 bg-slate-25 dark:bg-slate-900 overflow-auto">
      <div class="flex justify-between items-center">
        <h1
          class="flex items-center text-2xl text-slate-900 dark:text-slate-100"
        >
          <fluent-icon
            icon="book"
            class="hidden ml-1 mr-2 rtl:ml-2 rtl:mr-1 md:block"
          />
          <span class="text-2xl font-medium text-slate-900 dark:text-slate-100">
            Knowledge 
            <span v-if="!isLoading" class="font-small">
              ( {{ knowledgeList.length }} / {{ datasetLimit }} )
            </span>
          </span>
        </h1>
        <div class="flex gap-4 items-center">
          <woot-button
            variant="smooth"
            color-scheme="secondary"
            icon="edit"
            v-if="knowledgeList.length > datasetLimit"
            @click="() => isSelectingActive = true" 
          >Select Active</woot-button>
          <woot-button
            variant="smooth"
            color-scheme="primary"
            icon="plus-sign"
            :disabled="knowledgeList.length >= datasetLimit"
            @click="() => handleAddKnowledge()" 
          >Create Knowledge</woot-button>
        </div>
      </div>

      <div class="flex flex-col gap-3 text-slate-600 dark:text-slate-300 w-full mb-4">
        <p
          class="mb-0 text-base font-normal line-clamp-5 sm:line-clamp-none tracking-[-0.1px]"
        >
          Seamlessly integrating your knowledge base into the AI, enabling smarter, more contextual interactions, driving better engagement and user satisfaction. Keep your AI up-to-date, accurate, and ready to handle even the most specialized queries with ease.
        </p>
      </div>
      <div v-if="visible !== null" class="fixed h-[100vh] w-[100vw] top-0 left-0" @click="visible = null"></div>
      <div v-if="!isLoading" class="grid grid-cols-1 lg:grid-cols-3 gap-2">
        <router-link 
          v-for="(base, index) in filteredList" 
          :key="index" :to="base.active ? `knowledge/view/${base.id}` : ''" 
          class="relative bg-slate-100 dark:bg-slate-700 dark:text-slate-50 grid grid-rows-2 p-4 rounded border-2 dark:border-slate-700/50 border-slate-200"
          :class="{'opacity-50 cursor-not-allowed':!base.active, 'hover:bg-slate-200 dark:hover:bg-slate-800 cursor-pointer':base.active}"
        >
              <div v-if="visible === index" class="z-50 absolute top-10 right-3 border-2 rounded-md bg-slate-500 z-50 p-5">
                <button class="bg-red-200 p-2 hover:bg-red-500" @click.prevent="handleDeleteKnowledge(base)">Delete</button>
              </div>
              <button class="absolute top-0 right-0 p-3 rounded-full border-2 hover:bg-slate-600/50" @click.prevent="visible = index" >
                <fluent-icon icon="more-vertical" />
              </button>
              <div>
                <span class="flex gap-2 items-center">
                  <fluent-icon
                    icon="document"
                    class="h-[50px] w-[50px]"
                  />
                  <span>
                    <h4 class="text-base font-semibold">{{ base.name }}</h4>
                    <span class="flex justify-between w-full text-xs">
                      {{ base.document_count ? `${base.document_count} docs .`: '' }} {{ base.word_count ? `${base.word_count} words`:'' }} {{ base.app_count ? `. ${base.app_count} linked apps`:'' }}
                    </span>
                  </span>
                  
                </span>
                <br>
              </div>
              <p class="text-sm text-slate-700 dark:text-slate-200">{{ 
                base.description ? base.description : `useful for when you want to answer queries about the ${base.name}`
              }}</p>
        </router-link>

      </div>

      <LoadingState v-if="isLoading" />

      <div v-if="knowledgeList.length == 0 && !isLoading" class="py-16 w-full flex justify-center items-center">
        <h1 class="font-light text-xl">There are no knowledge created</h1>
      </div>

      <div v-if="isDeleting" class="z-40 fixed top-0 left-0 h-[100vh] w-[100vw] bg-slate-100/50" @click="cancelDelete()"></div>

      <div 
        v-if="isDeleting" 
        class="z-50 md:min-w-[300px] fixed border-2 top-2/4 left-2/4 -translate-x-2/4 -translate-y-2/4 p-4 rounded-md bg-slate-50 dark:bg-slate-900"
      >
        <h1 class="mb-8">Confirm delete {{ deleteTarget ? deleteTarget.name : '' }}?</h1>
        <div class="w-full flex justify-between mt-8">
        <woot-button
          variant="smooth"
          color-scheme="secondary"
          icon="dismiss-circle" 
          @click="cancelDelete()"
        >
          Cancel
        </woot-button>
        <woot-button
          variant="smooth"
          color-scheme="alert"
          icon="file-upload" 
          @click="() => handleConfirmDelete()"
        >
          Delete
        </woot-button>
      </div>
      </div>

      <div v-if="isSelectingActive" class="z-40 fixed top-0 left-0 h-[100vh] w-[100vw] bg-slate-100/50"></div>

      <div 
        v-if="isSelectingActive"
        class="max-h-[80vh] overflow-y-auto z-50 rounded-md border-2 p-4 fixed top-2/4 left-2/4 -translate-x-2/4 -translate-y-2/4 bg-slate-50 dark:bg-slate-700"
      >
        <h1 class="mb-4">Please select <span class="text-red-500">{{datasetLimit}}</span> out of these knowledges to be kept active:</h1>
        <div class="grid grid-flow-rows auto-rows-5 gap-4 my-4 overflow-y-auto">
          <label  
            v-for="base in knowledgeList"
            :key="base.id" 
            class="flex justify-between items-center p-4 rounded-md [has(input:disabled)]:bg-slate-100/50 [has(input:disabled)]:dark:bg-slate-500/50"
            :class="{
              'bg-slate-100 hover:bg-slate-200 dark:bg-slate-500 hover:dark:bg-slate-600 cursor-pointer' : selectedKnowledgeList.length < datasetLimit || selectedKnowledgeList.includes(base.id),
              'opacity-50 cursor-not-allowed':selectedKnowledgeList.length >= datasetLimit && !selectedKnowledgeList.includes(base.id)}"          
          >
            {{ base.name }}
            <input
              type="checkbox" 
              v-model="selectedKnowledgeList" 
              :value="base.id"
              class="h-5 w-5 rounded-md border-2 cursor-pointer shadow-inner disabled:cursor-not-allowed disabled:bg-slate-100/50 dark:disabled:bg-slate-500/50"
              :disabled="selectedKnowledgeList.length >= datasetLimit && !selectedKnowledgeList.includes(base.id)"
            />
          </label>
        </div>
        <div class="flex justify-end">
          <woot-button 
          variant="smooth"
          color-scheme="secondary" 
          @click="handleSelectActive"
          :disabled="selectedKnowledgeList.length == 0"
        >
          Confirm
        </woot-button>
        </div>
      </div>

      <div v-if="isRerouting" class="z-40 fixed top-0 left-0 h-[100vh] w-[100vw] bg-slate-100/50"></div>

      <div 
        v-if="isRerouting"
        class="z-50 rounded-md border-2 p-4 fixed top-2/4 left-2/4 -translate-x-2/4 -translate-y-2/4 bg-slate-100 dark:bg-slate-700"
      >
        <h1>Pleasse select an inbox to be kept active first.</h1>
        <br>
        <div class="flex justify-between">
          <woot-button 
            variant="smooth"
            color-scheme="primary"
          > 
            <router-link :to="{name: 'settings_inbox_list'}">Go to Inbox Settings</router-link>
          </woot-button>
          <woot-button 
            variant="smooth"
            color-scheme="alert"
            @click="$router.back()"
          > 
            Cancel
          </woot-button>
        </div>
      </div>
    </div>
</template>