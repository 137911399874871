import Cookies from 'js-cookie';
import { DEFAULT_REDIRECT_URL } from 'dashboard/constants/globals';
import { frontendURL } from 'dashboard/helper/URLHelper';
import store from '../store';

export const hasAuthCookie = () => {
  return !!Cookies.get('cw_d_session_info');
};

const getSSOAccountPath = ({ ssoAccountId, user }) => {
  const { accounts = [], account_id = null } = user || {};
  const ssoAccount = accounts.find(
    account => account.id === Number(ssoAccountId)
  );
  let accountPath = '';
  if (ssoAccount) {
    accountPath = `accounts/${ssoAccountId}`;
  } else if (accounts.length) {
    // If the account id is not found, redirect to the first account
    const accountId = account_id || accounts[0].id;
    accountPath = `accounts/${accountId}`;
  }
  return accountPath;
};

const accountId = store.getters['getCurrentAccountId'];

const getSubscription = async () => {
  const xApiKey = process.env.VUE_APP_X_API_KEY;
  const apiUrl = process.env.VUE_APP_KNOWLEDGE_API;
  try {
    const rawResponse = await fetch(
      `${apiUrl}/account/${accountId}/subscription`,
      {
        headers: {
          'X-API-KEY': xApiKey,
        },
      }
    );
    if (rawResponse.ok) {
      const response = await rawResponse.json();
      return response.status;
    }
  } catch (err) {
    console.error(err);
  }
};

// Redirect to pricing table to check for subscription first
export const getLoginRedirectURL = async ({
  ssoAccountId,
  ssoConversationId,
  user,
}) => {
  const status = await getSubscription();
  const accountPath = getSSOAccountPath({ ssoAccountId, user });
  if (accountPath) {
    if (status !== 'active') {
      return frontendURL(`${accountPath}/subscription`);
    } else if (ssoConversationId) {
      return frontendURL(`${accountPath}/conversations/${ssoConversationId}`);
    } else {
      return DEFAULT_REDIRECT_URL;
    }
  }
  return DEFAULT_REDIRECT_URL;
};
