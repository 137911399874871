<script>
    export default {
      name: "AddKnowledge",
      methods: {
        stepDisplay(number){
          if((this.$route.name === 'add_knowledge_processing' || this.$route.name === 'add_knowledge_finishing') && number === 1){
            return '✔';
          } else if(this.$route.name === 'add_knowledge_finishing' && number === 2){
            return '✔';
          } else {
            return `${number}`;
          }
        },   
      }
    }
</script>

<template>
  <div class="flex w-full h-full">
    <div class="flex flex-col gap-2 w-48 h-full px-2 pb-8 overflow-auto text-sm bg-white border-r dark:bg-slate-900 dark:border-slate-800">
      <div class="flex gap-2 items-center p-2 mt-2 text-md border rounded-md text-slate-700 dark:text-slate-200 border-slate-50 dark:border-slate-800">
        <router-link :to="{name: 'knowledge'}" class="p-1 hover:bg-slate-50 dark:hover:bg-slate-800 rounded-full">
          <fluent-icon icon="chevron-left" class="h-3 w-3" />
        </router-link>
        <h1>Create Knowledge</h1>
      </div>
      <router-link :to="{name: 'add_knowledge_upload'}" class="p-2 text-xs rounded-md flex items-center gap-4 pointer-events-none" active-class="text-woot-500 border-woot-500 text-woot-500">
        <span class="border-2 rounded-full h-6 w-6 p-1 flex justify-center items-center">{{ stepDisplay(1) }}</span>
        Choose a Data Source
      </router-link>
      <router-link :to="{name: 'add_knowledge_processing'}" class="p-2 text-xs rounded-md flex items-center gap-4 pointer-events-none" active-class="text-woot-500 border-woot-500 text-woot-500">
        <span class="border-2 rounded-full h-6 w-6 p-1 flex justify-center items-center">{{ stepDisplay(2) }}</span>
        Text Preprocessing
      </router-link>
      <router-link :to="{name: 'add_knowledge_finishing'}" class="p-2 text-xs rounded-md flex items-center gap-4 pointer-events-none" active-class="text-woot-500 border-woot-500 text-woot-500">
        <span class="border-2 rounded-full h-6 w-6 p-1 flex justify-center items-center">{{ stepDisplay(3) }}</span>
        Execute and Finish
      </router-link>
    </div>
    <router-view></router-view>
  </div>
</template>

