import { frontendURL } from '../../../helper/URLHelper';
import addKnowledge from './addKnowledge/routes';
import viewKnowledge from './viewKnowledge/routes';
import Knowledge from './Knowledge.vue';
import store from '../../../store/index';

export default {
  routes: [
    {
      path: frontendURL('accounts/:accountId/knowledge'),
      name: 'knowledge',
      component: Knowledge,
      meta: {
        permissions: ['administrator', 'restricted'],
      },
      // Router guard to check subscription
      beforeEnter: async (to, from, next) => {
        if (store.getters['stripe/getSubscriptionStatus'] === null) {
          const id = to.params.accountId;
          await store.dispatch('stripe/fetchSubscription', { id });
        }
        if (store.getters['stripe/getSubscriptionStatus'] !== 'active') {
          return next({ name: 'renew_subscription' });
        }

        next();
      },
    },
    ...addKnowledge.routes,
    ...viewKnowledge.routes,
  ],
};
