<script>
import { mapActions, mapState } from 'vuex';

export default {
  name: "DragAndDrop",
  data() {
    return {
      fileName: null,
      fileSize: null,
      fileType: null,
      validFileTypes: [
        'text/plain',
        'text/markdown',
        'application/pdf',
        'text/html',
        'text/xml',
        'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
        'application/vnd.ms-excel',
        'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
        'text/csv',
        'application/vnd.ms-powerpoint',
        'application/vnd.openxmlformats-officedocument.presentationml.presentation',
        'application/xml',
        'application/epub+zip',
        'message/rfc822',
        'application/vnd.ms-outlook'
      ],
    }
  },
  computed: {
    ...mapState('knowledge', ['uploadedFile', 'isUploaded']),
    acceptedFileTypes() {
      return this.validFileTypes.join(',');
    },
  },
  methods: {
    ...mapActions('knowledge', ['handleFileUpload', 'handleDrop', 'handleRemoveFile']),
    handleDragOver(event) {
      event.preventDefault();
      event.dataTransfer.dropEffect = 'copy';
    },
    triggerFileSelect() {
      this.$refs.fileInput.click();
    },
    triggerFileUpload(event) {
      
      let files = event.target.files;

      if (files) {
        let file = files[0]; 
        this.fileName = file.name;
        this.fileSize = file.size;
        this.fileExtension = file.name.split('.').pop();
        this.mimeType = file.type;

        if (!this.validFileTypes.includes(file.type)) {
          alert('Invalid file type. Please upload a valid file.');
          this.$refs.fileInput.value = ''; 
          return;
        }

        this.handleFileUpload(file);
        this.updateIsUploaded(true);
      } else {
        console.error('File upload failed.')
      }
    },
  }
};
</script>

<template>
  <div>
    <div
      v-if="!isUploaded" 
      class="drop-zone bg-slate-200 dark:bg-slate-800" 
      @dragover.prevent.stop="handleDragOver"
      @drop.prevent.stop="handleDrop"
      @click="triggerFileSelect"
      tabindex="0"
      role="button"
    >
      <input 
        type="file" 
        ref="fileInput" 
        class="hidden" 
        @change="triggerFileUpload"
        :accept="acceptedFileTypes" 
      />
      <p>Drag files here or <span class="underline text-woot-500 cursor-pointer">click to select files</span></p>
      <br>
      <p class="text-xs text-slate-500 dark:text-slate-300">
        Supports TXT, MARKDOWN, PDF, HTML, XLSX, XLS, DOCX, CSV, EML, MSG, PPTX, PPT, XML, EPUB. Max 15MB each.
      </p>
    </div>

    <div v-else class="border-2 bg-slate-200 dark:bg-slate-800 flex rounded-md px-5 py-2 justify-between max-w-xl items-center">
      <fluent-icon icon="file-upload" class="h-[30px] w-[30px]"/>
      <span>
        <h1>{{ fileName }}</h1>
        <p class="font-light">{{ ( fileSize / 1000000).toFixed(2) }} MB</p>
      </span>
      <button @click="handleRemoveFile" class="hover:bg-slate-300 dark:hover:bg-slate-700 p-2 rounded-full">
        <fluent-icon icon="delete" />
      </button>
    </div>
  </div>
</template>

<style scoped>
  .drop-zone {
    border-radius: 5px;
    padding: 20px;
    text-align: center;
    cursor: pointer;
  }
  .hidden {
    display: none;
  }
</style>