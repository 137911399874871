/* eslint arrow-body-style: 0 */
import { frontendURL } from '../../../helper/URLHelper';
import store from '../../../store/index';
const ContactsView = () => import('./components/ContactsView.vue');
const ContactManageView = () => import('./pages/ContactManageView.vue');

export const routes = [
  {
    path: frontendURL('accounts/:accountId/contacts'),
    name: 'contacts_dashboard',
    meta: {
      permissions: ['administrator', 'agent', 'restricted'],
    },
    component: ContactsView,
    // Router guard to check subscription
    beforeEnter: async (to, from, next) => {
      if (store.getters['stripe/getSubscriptionStatus'] === null) {
        const id = to.params.accountId;
        await store.dispatch('stripe/fetchSubscription', { id });
      }
      if (store.getters['stripe/getSubscriptionStatus'] !== 'active') {
        return next({ name: 'renew_subscription' });
      }

      next();
    },
  },
  {
    path: frontendURL('accounts/:accountId/contacts/custom_view/:id'),
    name: 'contacts_segments_dashboard',
    meta: {
      permissions: ['administrator', 'agent'],
    },
    component: ContactsView,
    props: route => {
      return { segmentsId: route.params.id };
    },
  },
  {
    path: frontendURL('accounts/:accountId/labels/:label/contacts'),
    name: 'contacts_labels_dashboard',
    meta: {
      permissions: ['administrator', 'agent'],
    },
    component: ContactsView,
    props: route => {
      return { label: route.params.label };
    },
  },
  {
    path: frontendURL('accounts/:accountId/contacts/:contactId'),
    name: 'contact_profile_dashboard',
    meta: {
      permissions: ['administrator', 'agent'],
    },
    component: ContactManageView,
    props: route => {
      return { contactId: route.params.contactId };
    },
  },
];
