import { frontendURL } from '../../../helper/URLHelper';
import Billing from './Billing.vue';

export default {
  routes: [
    {
      path: frontendURL('accounts/:accountId/billing'),
      name: 'billing',
      component: Billing,
      meta: {
        permissions: ['administrator', 'agent'],
      },
    },
  ],
};
