<script>
  import DragAndDrop from '../../components/DragAndDrop.vue';
  import FireCrawl from '../../components/FireCrawl.vue';
  import notionLogo from '../../../../../assets/images/notion_app_logo.png'
  import { mapState, mapActions } from 'vuex';

  export default {
    name: 'Upload',
    components: {
      DragAndDrop,
      FireCrawl
    },
    computed: {
      ...mapState(
          'knowledge', ['isUploaded', 'crawlResult']
        ),
    },
    data() {
      return {
        source: 'file',
        notionLogo: notionLogo,
        selectedResult: [],
      }
    },
    methods: {
      ...mapActions('knowledge', ['handleUpdateCrawlResult', 'handleRemoveFile', 'updateIsUploaded']),
      changeSource(source) {
        this.source = source;
      },
      handleNext(){
        this.handleUpdateCrawlResult(this.selectedResult);
        this.$router.push({name: 'add_knowledge_processing'});
      }
    },
    watch:{
      crawlResult:{
        handler(result){
          if (result && result.data) {
            this.selectedResult = result.data;
          }
        },
        deep: true
      }
    },
    mounted(){
      this.handleRemoveFile();
      this.updateIsUploaded(false);
    },
  }
</script>

<template>
  <div class="p-2 md:p-8 flex flex-1 h-full gap-3 flex-col m-0 bg-slate-25 dark:bg-slate-900 overflow-auto">

    <div class="flex justify-between">
      <h1 class="text-2xl text-slate-900 dark:text-slate-100">
        Choose data source
      </h1>
      <button 
        :disabled="!isUploaded" 
        @click="handleNext()"
        class="inline-block bg-woot-500 text-slate-100 px-4 py-2 rounded-md hover:bg-woot-700 disabled:bg-gray-400 disabled:cursor-not-allowed"
      >
        Next
      </button>
    </div>

    <div class="flex gap-2 my-6 ">
        <button 
          @click="changeSource('file')" 
          :class="{
            'text-woot-500 border-woot-500 dark:bg-slate-800 dark:border-woot-500': source === 'file',
            'border-slate-200 dark:border-slate-200': source !== 'file'
          }" 
          class='border-2 p-3 rounded-md hover:bg-slate-300 dark:hover:bg-slate-600 flex gap-2 items-center'>
            <fluent-icon icon="file-upload" class="h-[30px] w-[30px]" />
            Import from file
        </button>
        <!-- <button 
          @click="changeSource('notion')" 
          :class="{
            'text-woot-500 border-woot-500 dark:bg-slate-800 dark:border-woot-500': source === 'notion',
            'border-slate-200 dark:border-slate-200': source !== 'notion'
          }" 
          class='border-2 p-3 rounded-md hover:bg-slate-300 dark:hover:bg-slate-600 flex gap-2 items-center'>
            <img :src="notionLogo" alt="img" class="h-[25px] w-[25px]" />
            Sync from Notion
        </button> -->
        <button 
          @click="changeSource('website')" 
          :class="{
            'text-woot-500 border-woot-500 dark:bg-slate-800 dark:border-woot-500': source === 'website',
            'border-slate-200 dark:border-slate-200': source !== 'website'
          }" 
          class='border-2 p-3 rounded-md hover:bg-slate-300 dark:hover:bg-slate-600 flex gap-2 items-center'>
            <fluent-icon icon="globe" class="h-[25px] w-[25px]" />
            Sync from website
        </button>
    </div>


    <div class="my-6 " v-if="source === 'file'">
        <h1>Upload file</h1>
        <div class="my-4 ">
            <DragAndDrop />
        </div>
    </div>

    <!-- <div v-if="source === 'notion'">
        <h1>Notion</h1>
        <div class="my-4 p-6 rounded-md bg-slate-200 dark:bg-slate-800">
            <div class="flex flex-1 gap-4 items-center mb-2">
              <img :src="notionLogo" alt="img" class="rounded-md h-[40px] w-[40px]" />
              <div>
                <p class="text-lg">Notion is not connected.</p>
                <p>To sync with Notion, connection to Notion must be established first.</p>
              </div>
            </div>
            <button class="border-2 border-slate-800 dark:border-slate-50 px-4 py-2 rounded-md hover:bg-slate-300 dark:hover:bg-slate-600 inline-block">Go to connect</button>
        </div>
    </div> -->

    <div v-if="source === 'website'">
        <h1>Website</h1>
        <div class="my-4 ">
          <FireCrawl/>
        </div>
    </div>

    <div v-if="crawlResult && crawlResult.data && crawlResult.data.length" class="space-y-4 break-all">
      <label
        v-for="(result, index) in crawlResult.data"
        :key="index"
        class="border overflow-hidden border-gray-300 rounded-lg shadow-sm p-6 flex items-center gap-4 cursor-pointer hover:shadow-md transition-shadow duration-300"
      >
        <input
          type="checkbox"
          v-model="selectedResult"
          :value="result"
          class="w-5 h-5 mt-1 text-blue-600 rounded focus:ring-2 focus:ring-blue-400"
        />
        <div class="flex-1">
          <h1 class="text-lg font-semibold text-gray-800">{{ result.metadata.title }}</h1>
          <p class="text-sm text-gray-500 mb-2">{{ result.metadata.description }}</p>
          <hr class="my-2 border-gray-200" />
          <p class="line-clamp-3 text-gray-700 text-sm">{{ result.markdown }}</p>
        </div>
      </label>
    </div>

    <!-- <hr/>

    <p class="flex text-woot-500 items-center gap-2 underline font-medium my-4 cursor-pointer ">
      <fluent-icon icon="folder" class="h-4 w-4" />
      I want to create an empty knowledge.
    </p> -->
  </div>
</template>

<style scoped>
.truncate-multiline {
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
}
</style>