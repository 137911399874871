<script>
  export default {
    name: "IndexMode",
    data(){
        return {
          indexMode: "high-quality",
          retrievalSettings: "vector-search",
          economicalTopK: 5,
          vectorSearchTopK: 5,
          vectorSearchScoreThreshold: 5,
          isVectorSearchRerankModel: true,
          isVectorSearchScoreThreshold: true,
          fullTextSearchTopK: 5,
          fullTextSearchScoreThreshold: 5,
          isFullTextSearchRerankModel: true,
          isFullTextSearchScoreThreshold: true,
          hybridSearchTopK: 5,
          hybridSearchScoreThreshold: 5,
          isHybridSearchScoreThreshold: true,
          isHybridSearch: true,
          hybridSearchValue: 0.5,
        }
    },
    methods:{
        setIndexMode(value){
          this.indexMode = value;
        },
        setRetrievalSettings(value){
          this.retrievalSettings = value;
        },
        updateEconomicalTopK(event){
          this.economicalTopK = event.target.value;
        },
        updateVectorSearchTopK(event){
          this.vectorSearchTopK = event.target.value;
        },
        updateVectorSearchScoreThreshold(event){
          this.vectorSearchScoreThreshold = event.target.value;
        },
        toggleVectorSearchRerankModel(){
          this.isVectorSearchRerankModel = !this.isVectorSearchRerankModel
        },
        toggleVectorSearchScoreThreshold(){
          this.isVectorSearchScoreThreshold = !this.isVectorSearchScoreThreshold
        },
        updateFullTextSearchTopK(event){
          this.fullTextSearchTopK = event.target.value;
        },
        updateFullTextSearchScoreThreshold(event){
          this.fullTextSearchScoreThreshold = event.target.value;
        },
        toggleFullTextSearchRerankModel(){
          this.isFullTextSearchRerankModel = !this.isFullTextSearchRerankModel
        },
        toggleFullTextSearchScoreThreshold(){
          this.isFullTextSearchScoreThreshold = !this.isFullTextSearchScoreThreshold
        },
        updateHybridSearchTopK(event){
          this.hybridSearchTopK = event.target.value;
        },
        updateHybridSearchScoreThreshold(event){
          this.hybridSearchScoreThreshold = event.target.value;
        },
        toggleHybridSearchScoreThreshold(){
          this.isHybridSearchScoreThreshold = !this.isHybridSearchScoreThreshold;
        },
        toggleHybridSearch(){
          this.isHybridSearch = !this.isHybridSearch;
        },
        updateHybridSearchValue(event){
          this.hybridSearchValue = event.target.value;
        }
    }
  }
</script>

<template>
  <div>
    <h1 class="mb-4">Index Mode</h1>
    <div class="grid grid-cols-2 gap-4">
      <label 
        class="flex justify-between border-2 p-6 rounded-md cursor-pointer hover:bg-slate-300 border-slate-200 dark:hover:bg-slate-700"
        :class="{'border-woot-500' : indexMode === 'high-quality', 'border-slate-900' : indexMode !== 'high-quality'}"
        @click="setIndexMode('high-quality')"
      >
        <div>
          <h1>High Quality</h1>
          <p>Call default system embedding interface for processing to provide higher accuracy when users query.</p>
        </div>
        <input name="test2" value="1" type="radio" checked />
      </label>
      <label 
        class="flex justify-between border-2 p-6 rounded-md cursor-pointer hover:bg-slate-300 border-slate-200 dark:hover:bg-slate-700"
        :class="{'border-woot-500' : indexMode === 'economical', 'border-slate-900' : indexMode !== 'economical'}"
        @click="setIndexMode('economical')"
      >
        <div>
          <h1>Economical</h1>
          <p>Use offline vector engines, keyword indexes, etc. to reduce accuracy without spending tokens</p>
        </div>
        <input name="test2" value="2" type="radio">
      </label>
    </div>

        <div v-if="indexMode === 'high-quality'" class="my-8 flex flex-col">
        <label>
          <h1 class="mb-4">Embedding model</h1>
        </label>
        <select>
            <option>text-embedding-3-large</option>
            <option>text-embedding-3-small</option>
            <option>text-embedding-ada-002</option>
        </select>
        <br>
        <label class="mb-4">
          <h1>Retrieval settings</h1>
          <p class="text-xs">Learn more about retrieval method, you can change this at any time in the Knowledge settings.</p>
        </label>
        <label 
            class="flex flex-col border-2 p-6 rounded-md cursor-pointer hover:bg-slate-300 border-slate-200 dark:hover:bg-slate-700"
            @click="setRetrievalSettings('vector-search')"
            :class="{'border-woot-500' : retrievalSettings === 'vector-search', 'border-slate-900' : retrievalSettings !== 'vector-search' }"
        >  
            <div class="flex justify-between">
            <div>
                <h1>Vector Search</h1>
                <p>Generate query embeddings and search for the text chunk most similar to its vector representation.</p>
            </div>
            <input name="retrieval-settings" value="2" type="radio" checked>
            </div>

            <div v-if="retrievalSettings === 'vector-search'" class="flex flex-col">
            <br>
            <hr>
            <br>
            <!-- <span class="flex gap-2">
                <input type="checkbox" @change="toggleVectorSearchRerankModel"/>
                <label>Rerank Model</label>
            </span> -->
            <select class="text-black" :disabled="isVectorSearchRerankModel">
                <option value="..." selected>...</option>
            </select>
            <br>
            <label>Top K</label>
            <p>{{ vectorSearchTopK }}</p>
            <input type="range" min="1" max="10" step="1" :value="vectorSearchTopK" @input="(e) => updateVectorSearchTopK(e)">
            <br>
            <span class="flex gap-2">
                <input type="checkbox" @change="toggleVectorSearchScoreThreshold"/>
                <label>Score Threshold</label>
            </span>
            <p>{{ vectorSearchScoreThreshold }}</p>
            <input 
                type="range" min="1" max="10" step="1" 
                :value="vectorSearchScoreThreshold" 
                @input="(e) => updateVectorSearchScoreThreshold(e)"
                :disabled="isVectorSearchScoreThreshold"
            >
            </div>
        </label>

        <br>

        <label 
            class="flex flex-col border-2 p-6 rounded-md cursor-pointer hover:bg-slate-300 border-slate-200 dark:hover:bg-slate-700"
            :class="{'border-woot-500' : retrievalSettings === 'full-text-search', 'border-slate-900' : retrievalSettings !== 'full-text-search'}"
            @click="setRetrievalSettings('full-text-search')"
        >
            <div class="flex justify-between">
            <div>
                <h1>Full-Text Search</h1>
                <p>Index all terms in the document, allowing users to search any term and retrieve relevant text chunk containing those terms.</p>
            </div>
            <input name="retrieval-settings" value="2" type="radio">
            </div>

            <div v-if="retrievalSettings === 'full-text-search'" class="flex flex-col">
            <br>
            <hr>
            <br>
            <span class="flex gap-2">
                <input type="checkbox" @change="toggleFullTextSearchRerankModel"/>
                <label>Rerank Model</label>
            </span>
            <select class="text-black" :disabled="isFullTextSearchRerankModel">
                <option value="..." selected>...</option>
            </select>
            <br>
            <label>Top K</label>
            <p>{{ fullTextSearchTopK }}</p>
            <input type="range" min="1" max="10" step="1" :value="fullTextSearchTopK" @input="(e) => updateFullTextSearchTopK(e)">
            <br>
            <span class="flex gap-2">
                <input type="checkbox" @change="toggleFullTextSearchScoreThreshold"/>
                <label>Score Threshold</label>
            </span>
            <p>{{ fullTextSearchScoreThreshold }}</p>
            <input 
                type="range" min="1" max="10" step="1" 
                :value="fullTextSearchScoreThreshold" 
                @input="(e) => updateFullTextSearchScoreThreshold(e)"
                :disabled="isFullTextSearchScoreThreshold"
            >
            </div>
        </label>

        <br>
        <label 
            class="flex flex-col border-2 p-6 rounded-md cursor-pointer hover:bg-slate-300 border-slate-200 dark:hover:bg-slate-700"
            :class="{'border-woot-500' : retrievalSettings === 'hybrid-search', 'border-slate-900' : retrievalSettings !== 'hybrid-search'}"
            @click="setRetrievalSettings('hybrid-search')"
        >
            <div class="flex justify-between">
            <div>
                <h1>Hybrid Search</h1>
                <p>Execute full-text search and vector searches simultaneously, re-rank to select the best match for the user's query. Users can choose to set weights or configure to a Rerank model.</p>
            </div>
            <input name="retrieval-settings" value="2" type="radio">
            </div>

            <div v-if="retrievalSettings === 'hybrid-search'" class="flex flex-col">
            <br>
            <hr>
            <br>
            <div class="flex gap-2">
                <button 
                class="px-4 py-1 border-2 rounded-md hover:bg-woot-500"
                :class="{'bg-woot-500 border-woot-500' : isHybridSearch}"
                @click="toggleHybridSearch"
                :disabled="isHybridSearch"
                >Weighted Score</button>
                <button 
                class="px-4 py-1 border-2 rounded-md hover:bg-woot-500"
                :class="{'bg-woot-500 border-woot-500' : !isHybridSearch}"
                @click="toggleHybridSearch"
                :disabled="!isHybridSearch"
                >Rerank Model</button>
            </div>
            <br>
            <div v-if="isHybridSearch">
                <div class="flex justify-between">
                <p>Semantic {{ hybridSearchValue }}</p>
                <p>Keyword {{ (1 - hybridSearchValue).toFixed(1) }}</p>
                </div>
                <input type="range" max="1.0" min="0" step="0.1" class="w-full" @input="(e) => updateHybridSearchValue(e)" :value="hybridSearchValue"/>
            </div>
            <div v-if="!isHybridSearch">
                <select>
                <option selected>...</option>
                </select>
            </div>
            <br>
            <label>Top K</label>
            <p>{{ hybridSearchTopK }}</p>
            <input type="range" min="1" max="10" step="1" :value="hybridSearchTopK" @input="(e) => updateHybridSearchTopK(e)">
            <br>
            <span class="flex gap-2">
                <input type="checkbox" @change="toggleHybridSearchScoreThreshold"/>
                <label>Score Threshold</label>
            </span>
            <p>{{ hybridSearchScoreThreshold }}</p>
            <input 
                type="range" min="1" max="10" step="1" 
                :value="hybridSearchScoreThreshold" 
                @input="(e) => updateHybridSearchScoreThreshold(e)"
                :disabled="isHybridSearchScoreThreshold"
            >
            </div>

        </label>
        </div>

        <div v-if="indexMode === 'economical'" class="my-8">
        <br>
        <label>Retrieval settings</label>
        <p>Learn more about retrieval method, you can change this at any time in the Knowledge settings.</p>
        <br>
        <div class="flex flex-col gap-4 p-6 rounded-md border-2 border-slate-900 max-w-xl">
            <div>
            <h1>Inverted Index</h1>
            <p>Inverted Index is a structure used for efficient retrieval. Organized by terms, each term points to documents or web pages containing it.</p>
            </div>
            <hr>
            <div>
            <label>Top k</label>
            <span class="flex gap-2">
                <p>{{ economicalTopK }}</p>
                <input type="range" min="1" max="10" step="1" :value="economicalTopK" @input="(e) => updateEconomicalTopK(e)">
            </span>
            </div>
        </div>
        </div>
        </div>
</template>