<script>
export default {
  props: {
    isChildMenuActive: {
      type: Boolean,
      default: false,
    },
    openInNewPage: {
      type: Boolean,
      default: true,
    },
  },
};
</script>

<template>
    <a
      v-tooltip.right="'Create Ticket'"
      href="https://dahreply.zohodesk.com/portal/en/newticket"
      class="text-slate-700 dark:text-slate-100 w-10 h-10 my-2 flex items-center justify-center rounded-lg hover:bg-slate-25 dark:hover:bg-slate-700 dark:hover:text-slate-100 hover:text-slate-600 relative"
      :class="{
        'bg-woot-50 dark:bg-slate-800 text-woot-500 hover:bg-woot-50':
        isChildMenuActive,
      }"
      rel='noopener noreferrer nofollow'
      target='_blank'
    >
      <fluent-icon
        icon="ticket"
        :class="{
          'text-woot-500': isChildMenuActive,
        }"
      />
      <span class="sr-only">Create Ticket</span>
    </a>
</template>
