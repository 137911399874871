<script>
    export default {
        name: 'ViewKnowledge',
        data(){
            return{
                knowledgeId: null,
            }
        },
        onMounted(){
          this.knowledgeId = this.$route.params.id;
        }
    }
</script>

<template>
    <div class="flex w-full h-full">
    <div class="flex flex-col gap-2 w-48 h-full px-2 pb-8 overflow-auto text-sm bg-white border-r dark:bg-slate-900 dark:border-slate-800">
      <div class="flex gap-2 items-center p-2 mt-2 text-md border rounded-md text-slate-700 dark:text-slate-200 border-slate-50 dark:border-slate-800">
        <router-link :to="{name: 'knowledge'}" class="p-1 hover:bg-slate-50 dark:hover:bg-slate-800 rounded-full">
          <fluent-icon icon="chevron-left" class="h-3 w-3" />
        </router-link>
        <h1>Knowledge</h1>
      </div>
      <router-link :to="{name: 'view_knowledge_documents'}" class="p-2 text-xs rounded-md flex items-center gap-4" active-class="text-woot-500 border-woot-500 text-woot-500">
        Documents
      </router-link>
      <!-- <router-link :to="{name: 'view_knowledge_testing'}" class="p-2 text-xs rounded-md flex items-center gap-4" active-class="text-woot-500 border-woot-500 text-woot-500">
        Testing
      </router-link>
      <router-link :to="{name: 'view_knowledge_settings'}" class="p-2 text-xs rounded-md flex items-center gap-4" active-class="text-woot-500 border-woot-500 text-woot-500">
        Settings
      </router-link> -->
    </div>
    <router-view></router-view>
  </div>
</template>

