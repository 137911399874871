import { frontendURL } from '../../../../helper/URLHelper';
import AddKnowledge from './index.vue';
import Upload from './upload/Upload.vue';
import Processing from './processing/Processing.vue';
import Finishing from './finishing/Finishing.vue';

export default {
  routes: [
    {
      path: frontendURL('accounts/:accountId/knowledge/add'),
      name: 'add_knowledge',
      component: AddKnowledge,
      meta: {
        permissions: ['administrator'],
      },
      redirect: { name: 'add_knowledge_upload' },
      children: [
        {
          path: 'upload',
          name: 'add_knowledge_upload',
          component: Upload,
          meta: {
            permissions: ['administrator'],
          },
        },
        {
          path: 'processing',
          name: 'add_knowledge_processing',
          component: Processing,
          meta: {
            permissions: ['administrator'],
          },
        },
        {
          path: 'finishing',
          name: 'add_knowledge_finishing',
          component: Finishing,
          meta: {
            permissions: ['administrator'],
          },
        },
      ],
    },
  ],
};
