<script>
  import IndexMode from '../../components/IndexMode.vue';

  export default {
    name: 'view_knowledge_settings',
    components: {
      IndexMode,
    }
  }
</script>

<template>
  <div class="p-2 md:p-8 flex flex-1 h-full gap-3 flex-col m-0 overflow-auto">
    <header class="flex justify-between items-center">
      <div>
        <h1 class="flex items-center mb-0 text-2xl font-medium text-slate-900 dark:text-slate-100">Knowledge Settings</h1>
        <p>
          Here you can modify the properties and working methods of the Knowledge.
        </p>
      </div>
      <button class="inline-block bg-woot-500 text-slate-100 px-4 py-2 rounded-md hover:bg-woot-700">Save</button>
    </header>
    <br>
    <div class="flex flex-col">
      <label>Knowledge Name</label>
      <input type="text" class="rounded-md ps-1 dark:bg-slate-800" value="default name" />
    </div>
    <br>
    <div class="flex flex-col">
      <label>Knowledge Description</label>
      <textarea  class="rounded-md ps-1 dark:bg-slate-800" value="some description here"></textarea>
    </div>
    <br>
    <div class="flex flex-col gap-2">
      <label>Permissions</label>
      <select class="rounded-md ps-1 dark:bg-slate-800">
          <option selected>only me</option>
      </select>
    </div>
    <IndexMode />
</div>
</template>